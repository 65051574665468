:root {
  --website-main-text-color: #000;
  --website-main-text-hover-color: #fff;
  --website-main-bg-color: #0093bf;
}
.hero {
  background-image: url("/public/HRTD-Background-banner.png");
  /* background-image: url('https://web.s-cdn.boostkit.dev/webaction-files/5aa10b463872830e1cab1d43_banner_builder/main-lp-61e15496233fffc8e9afea94.jpg'); */
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: auto;
  padding: 2% 0;
  color: var(--website-main-text-color);
}
.heroContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 20px;
  width: 100%;
}
.courseProfile {
  background-color: var(--website-main-text-hover-color);
  border-radius: 10px;
  box-shadow: 0px 0px 1px 1px var(--website-main-bg-color);
  overflow: hidden;
}
.courseProfile:hover {
  box-shadow: 0px 0px 3px 3px var(--website-main-bg-color);
}
.courseHeading h2 {
  padding: 1rem 0px;
  color: var(--website-main-text-hover-color);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  background-color: var(--website-main-bg-color);
}
.courseCategoryPicture {
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.courseCategoryPicture img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  transition: all 0.5s ease-in;
}
.courseProfile:hover img {
  transform: scale(1.05);
}
.courseList {
  display: flex;
  flex-direction: column;
  margin-top: 1px;
}
.courseList a {
  padding-bottom: 4px;
  padding: 0 5px 5px 9px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px dotted var(--website-main-bg-color);
  color: #000;
}
.courseList a:hover {
  background-color: var(--website-main-bg-color);
  color: var(--website-main-text-hover-color);
}
.courseList a:last-child {
  border-bottom: none;
}

.contactBg {
  background-color: #e1dede;
  padding: 1rem 0;
}
.contact {
  border-radius: 5px;
}
.contact .shadow {
  padding: 1rem;
}
.contact iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.contact h2 {
  font-size: 18px;
  font-weight: 500;
}
.contact h3 {
  margin-top: 16px;
  font-weight: 500;
}
.contact .right p {
  font-size: 14px;
  padding: 10px 0;
  text-align: justify;
}
.contact .right .box p {
  padding-top: 2px;
}

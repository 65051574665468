:root {
  --website-main-bg-color: #00a0c6;
  --website-main-text-color: #fff;
  --website-gray-color: gray;
}
section.testimonial {
  padding: 2rem 0;
}
.testimonial .items {
  padding: 15px;
  transition: 0.5s;
  border-radius: 5px;
}
.testimonial .box {
  display: flex;
}
.testimonial .img {
  position: relative;
}
.testimonial img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonial i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: var(--website-main-bg-color);
  color: var(--website-main-text-color);
}
.testimonial .name {
  margin: 0px 25px;
}
.testimonial .name h2 {
  font-size: 16px;
  margin-bottom: 5px;
}
.testimonial .name span {
  color: var(--website-main-bg-color);
  font-size: 12px;
}
.testimonial .name h3 {
  font-size: 14px;
  margin: 10px 0;
  color: var(--website-main-bg-color);
}
.testimonial .items:hover h3 {
  color: var(--website-main-text-color);
}
.testimonial p {
  margin-top: 20px;
  color: var(--website-gray-color);
  transition: 0.5s;
  text-align: justify;
  font-size: 14px;
  line-height: 20px;
}
.testimonial .items:hover {
  background-color: var(--website-main-bg-color);
  color: var(--website-main-text-color);
  cursor: pointer;
}
.testimonial .items:hover p,
.testimonial .items:hover span {
  color: var(--website-main-text-color);
}
.testimonial .items:hover i {
  background-color: var(--website-main-text-color);
  color: var(--website-main-bg-color);
}

@media screen and (max-width: 768px) {
  .testimonial img {
    width: 50px;
    height: 50px;
  }
  .testimonial i {
    left: 20px;
  }
}

/* Insert Testimonial CSS */
.insert-form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.insert-testimonial-form {
  width: 100%;
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.insert-testimonial-form .insert-input-group {
  display: flex;
  gap: 10px;
  width: 100%;
  min-width: 240px;
  flex-grow: 1;
}

.insert-testimonial-form .input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 240px;
  flex-grow: 1;
}

.insert-testimonial-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.insert-testimonial-form .input-group input,
.insert-testimonial-form .input-group textarea {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}
.insert-testimonial-form .input-group p {
  font-size: 12px;
  line-height: 20px;
}

.content.view {
  width: 70%;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .insert-testimonial-form .insert-input-group {
    flex-direction: column;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.backshadow {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.backshadow .custom-modal {
  width: 25rem;
  height: 30rem;
  background-color: #fff;
  position: relative;
  padding: 1rem;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 25px 4px rgba(82, 71, 82, 0.78);
  -moz-box-shadow: 0px 0px 25px 4px rgba(82, 71, 82, 0.78);
  box-shadow: 0px 0px 25px 4px rgba(82, 71, 82, 0.78);
}
.backshadow .delete-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -15px;
}
.bkashQRCode {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bkashQRCode img {
  width: 80%;
  height: 100%;
}
.bank-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bank-details h2 {
  padding: 1rem;
  background-color: red;
  color: #fff;
  border-radius: 1rem;
}

@media (max-width: 680px) {
  .backshadow .custom-modal {
    width: 19rem;
    height: 25rem;
  }
}

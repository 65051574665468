.findYoutubeHeading {
  text-align: center;
  margin: 1rem;
}
.youtube-form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.insert-youtube-form {
  width: 100%;
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.insert-youtube-form .input-group {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 307px;
  flex-grow: 1;
}

.insert-youtube-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.insert-youtube-form .input-group input {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}

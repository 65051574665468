.awrapper {
  /* background-image: url("../../../public/images/bg_blur.png"); */
  /* background-image: url("https://img.freepik.com/free-vector/set-yellow-green-pink-purple-scene-background-with-podium-product-display_601576-124.jpg?w=2000"); */
  background: rgb(0, 160, 198);
  background: linear-gradient(
    90deg,
    rgba(0, 160, 198, 1) 19%,
    rgba(81, 149, 15, 1) 50%,
    rgba(0, 160, 198, 1) 78%
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--website-main-text-color);
  padding: 2rem 0;
}
.awrapper .box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.awrapper .img {
  width: 5rem;
  height: 5rem;
}
.awrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.awrapper .text h2 {
  font-size: 3em;
  font-weight: 700;
}
.awrapper h3 {
  font-size: 1.2em;
  font-weight: 500;
}

.findCounterHeading {
  text-align: center;
  margin: 1rem;
}
.counter-form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.insert-counter-form {
  width: 100%;
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.insert-counter-form .input-group {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 250px;
  flex-grow: 1;
}

.insert-counter-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.insert-counter-form .input-group input {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}

@media screen and (max-width: 768px) {
  .awrapper {
    height: auto;
  }
  .awrapper .box {
    padding: 15px;
  }
  .awrapper .text h2 {
    font-size: 2.3em;
    font-weight: 700;
  }
  .awrapper h3 {
    font-size: 1em;
    font-weight: 500;
  }
}

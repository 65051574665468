:root {
  --website-main-bg-color: #00a0c6;
  --website-main-text-color: #fff;
  --website-header-bg-color: #00a0c6;
  --website-menu-hover-text-color: #85d9e8;
  --website-back-header-bg-color: #b3d9de;
  --website-other-text-color: #000;
  --website-get-start-bg-color: #70be44;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}
.head {
  padding: 5px 0;
  background-color: var(--website-back-header-bg-color);
  color: var(--website-other-text-color);
}
.head .flexSB {
  display: flex;
  gap: 10px;
  align-items: center;
}
.header-logo-part {
  display: flex;
  gap: 10px;
  align-items: center;
}
.header-logo-part img {
  width: 50px;
}
.logo h1 {
  font-size: 25px;
  font-weight: 900;
}
.logo span {
  font-size: 16px;
}
.founded h2 {
  background-color: var(--website-header-bg-color);
  padding: 5px 20px;
  border-radius: 50px;
  color: var(--website-main-text-color);
}
.head .icon {
  margin-left: 10px;
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
button {
  border: none;
}

/*-------------------icon ---------------------*/
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--website-get-start-bg-color);
  color: var(--website-main-text-color);
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: var(--website-main-bg-color);
  color: var(--website-menu-hover-text-color);
}

/* --------------------Header ------------------*/
header {
  background-color: var(--website-header-bg-color);
}
header nav {
  position: relative;
}
header .desktop-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .left-part a {
  padding: 7px 10px;
  display: inline-block;
  color: var(--website-main-text-color);
}
header .left-part a:hover,
header .left-part a.active {
  background-color: var(--website-get-start-bg-color);
}
.right-part a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  background-color: var(--website-get-start-bg-color);
  color: var(--website-main-text-color);
  font-weight: 700;
}
.toggle {
  display: none;
}

/*---------- Back Section-------------------*/
.back {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 8vh;
  background-color: var(--website-back-header-bg-color);
  color: var(--website-other-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.back h2 {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

/* ------------Admin part ------------------*/
.authLogout {
  display: flex;
  gap: 10px;
  align-items: center;
}
.authLogout h2 {
  font-size: 1em;
  color: var(--website-main-text-color);
  padding: 7px 0;
}
.authLogout span {
  background-color: var(--website-main-text-color);
  padding: 3px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}
.authLogout span:hover {
  background-color: var(--website-back-header-bg-color);
}
.authLogout span img {
  width: 20px;
}

/* update header style */
@media screen and (max-width: 912px) {
  .logo {
    flex: 2;
  }
  .logo h1 {
    font-size: 20px;
  }
  .logo span {
    font-size: 14px;
  }
  .founded h2 {
    font-size: 15px;
    padding: 10px 15px;
  }
  header .left-part a {
    padding: 8px 14px;
  }
}
@media screen and (max-width: 767px) {
  .head .flexSB {
    flex-direction: column;
  }
  .logo h1 {
    font-size: 20px;
  }
  .logo span {
    font-size: 12px;
  }
  .founded h2 {
    font-size: 15px;
    padding: 5px 15px;
  }
  .toggle {
    display: block;
    background: none;
    color: var(--website-main-text-color);
    font-size: 25px;
    position: absolute;
    top: 5px;
    right: 0;
  }
  header .desktop-nav .left-part {
    display: none;
  }
  header .mobile-nav {
    padding-top: 35px;
    padding-bottom: 15px;
  }
  header .mobile-nav .left-part {
    display: flex;
    flex-direction: column;
  }

  /* Admin responsive */
  .addItemsHeading {
    margin: 0.8rem;
    font-size: 25px;
  }
  .add-item-group .add-item a {
    font-size: 15px;
  }
  .addButton h1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 640px) {
  .logo h1 {
    font-size: 20px;
  }
}

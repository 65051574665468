.resultBg {
  background-color: #e1dede;
}
.findResultHeading {
  text-align: center;
  margin: 1rem;
}
.form-data,
.insert-form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.result-form,
.insert-result-form {
  width: 60%;
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.insert-result-form {
  width: 100%;
}

.result-form .input-group,
.insert-result-form .input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 260px;
  flex-grow: 1;
}
.insert-result-form .input-group {
  width: 45%;
}

.result-form .input-group label,
.insert-result-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.result-form .input-group input,
.insert-result-form .input-group input {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}

.insert-result-form .input-group p {
  font-size: 12px;
  line-height: 20px;
}

.result-details {
  background-color: white;
  width: 80%;
  margin: 1rem auto;
  border-radius: 10px;
  padding: 1rem;
}
.view .result-details {
  width: 100%;
}
.resultWebTitle {
  text-align: start;
}

.result-details .picture {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.result-details .picture .institute {
  display: flex;
  gap: 10px;
}
.result-details .picture .institute img {
  width: 59px;
  height: 75px;
}
.result-details .picture h2 {
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.result-details .picture p {
  margin-top: -4px;
  font-size: 14px;
}
.result-details .institute .resultCourseTitle {
  margin-top: 1rem;
}
.result-details .picture h3 {
  font-size: 16px;
}
.result-details img {
  width: 120px;
  height: 130px;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.result-group {
  display: grid;
  grid-template-columns: 1fr 1rem 2fr;
  background: #eeeeee;
  padding: 10px;
  margin-bottom: 5px;
  align-items: center;
  font-style: italic;
}
.result-group h2 {
  font-size: 16px;
}
.result-group span {
  font-weight: 900;
  padding-right: 10px;
}
.userRegError {
  text-align: center;
  color: red;
  font-size: 22px;
  font-weight: 700;
}
.printBtn button {
  background-color: #00a0c6;
  color: white;
  border-radius: 10px;
  padding: 10px 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

/* Edit Result Profile Picture */
.editProfilePicture {
  display: grid;
  grid-template-columns: auto 70px;
  grid-gap: 10px;
}
.editProfilePicture img {
  width: 100%;
  height: 70px;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .result-form,
  .result-details {
    width: 100%;
  }
  .result-details .picture {
    display: block;
    text-align: center;
  }
  .result-details .picture h2 {
    font-size: 16px;
    letter-spacing: 0px;
    margin-bottom: 5px;
  }
  .result-details .picture p {
    margin-top: -4px;
    font-size: 11px;
  }
  .result-details .picture .institute img {
    width: 48px;
    height: 60px;
  }
  .result-details .studentPicture img {
    margin: 1rem;
  }
  .result-group {
    grid-template-columns: 1fr 1rem;
  }
}

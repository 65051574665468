@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --website-body-bg-color: #f4f4f4;
  --website-main-text-color: #fff;
  --website-header-bg-color: #00a0c6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: var(--website-body-bg-color);
}
div#root {
  min-width: 340px;
  margin: auto;
}
h1 {
  font-size: 18px;
}
h2 {
  font-size: 16px;
}
h3 {
  font-size: 14px;
}
h4 {
  font-size: 12px;
}
p {
  font-size: 14px;
}
.container {
  min-width: 300px;
  max-width: 1440px;
  margin: auto;
}
.subContainer {
  max-width: 85%;
  margin: auto;
}
.flexSB {
  display: flex;
  justify-content: space-between;
}
.input-group {
  display: flex;
  flex-direction: column;
}

.submit-btn {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 1rem 0;
}

.submit-btn input,
.submit-btn a,
.submit-btn button {
  border: none;
  background: #00a0c6;
  border-radius: 5px;
  padding: 8px 20px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.submit-btn input:hover,
.submit-btn a:hover,
.submit-btn button:hover {
  background-color: #027491;
  cursor: pointer;
}
.updateButton button {
  cursor: pointer;
}

.loadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.loadingSpinner img {
  width: 40px !important;
  height: 40px !important;
}
.countSpinner img {
  width: 20px !important;
  height: 20px !important;
}
.loadingBtnSpinner img {
  width: 10px !important;
  height: 10px !important;
}

.disableBtn,
.disableBtn:hover {
  background-color: #c7c7c7 !important;
  color: black !important;
  border: 1px solid gray !important;
  cursor: not-allowed;
}

/*-------------Hero--------------*/
#heading {
  text-align: center;
  padding: 10px 0;
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--website-header-bg-color);
  text-transform: uppercase;
}
#heading h1 {
  font-size: 30px;
  padding: 20px 0;
  text-transform: capitalize;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 20px;
  width: 100%;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  width: 100%;
}
.grid3 {
  display: grid;
  grid-template-columns: 2.5fr 2fr 1.5fr;
  grid-gap: 20px;
  width: 100%;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  width: 100%;
}
.courseOnlyOne {
  max-width: 560px;
}

.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid var(--website-header-bg-color);
  width: 100%;
  transition: 0.5s;
}
.outline-btn:hover {
  background-color: var(--website-header-bg-color);
  color: var(--website-main-text-color);
}

.padding {
  padding: 80px 0;
}
.shadow {
  box-shadow: 0 5px 25px -2px rgba(0 0 0 /6%);
  background-color: var(--website-main-text-color);
}
.aboutUs {
  padding-bottom: 1rem;
}
.aboutUs .featureHome {
  margin-top: 0px;
}
#react-select-3-listbox {
  z-index: 9999;
}

/* Admin CSS */
.itemContainer {
  background-color: #e5e5e5;
  width: 100%;
  margin: 1rem auto;
  padding: 1rem;
}
.tableBtn {
  padding-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .subContainer {
    width: 100%;
  }
  .grid3 {
    grid-template-columns: 1fr;
  }
  .addButton {
    flex-direction: column;
    gap: 1rem;
  }
}

/* Blogs details */
.blog-description .blog-title h2 {
  font-size: 1.5em;
  text-align: justify;
  margin-bottom: 8px;
}

.blog-desc h2,
.blog-desc h3,
.blog-desc h4 {
  padding: 10px 0;
}

.blog-desc h2 {
  font-size: 18px;
}

.blog-desc p {
  font-size: 14px;
  line-height: 25px;
  text-align: justify;
  margin-bottom: 1rem;
}

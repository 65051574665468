.faqSection {
  background-color: #fff;
  max-width: 100%;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem 0;
}
.faq .box {
  background-color: #1eb2a6;
  color: #fff;
  margin-bottom: 20px;
}
.faq button {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
  outline: none;
}
.faq h2 {
  font-weight: 500;
}
.faq p {
  padding: 20px;
}
.faq .accordion {
  transition: 0.5s;
}
.faq .accordion:hover {
  background-color: #1eb2a6;
  color: #fff;
}

/* Insert faq details for admin */
.findFaqHeading {
  text-align: center;
  margin: 1rem;
}
.insert-form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.insert-faq-form {
  width: 100%;
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.insert-faq-form .input-group {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 307px;
  flex-grow: 1;
}

.insert-faq-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.insert-faq-form .input-group input,
.insert-faq-form .input-group textarea,
.insert-faq-form .input-group select {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}

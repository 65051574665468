:root {
  --website-body-bg-color: #eeeeee;
  --website-main-bg-color: #00a0c6;
  --website-main-text-color: #fff;
  --website-header-bg-color: #00a0c6;
  --website-menu-hover-text-color: #85d9e8;
  --website-back-header-bg-color: #b3d9de;
  --website-other-text-color: #000;
  --website-get-start-bg-color: #70be44;
  --website-secondary-text-color: gray;
}
.newsletter {
  background-color: var(--website-header-bg-color);
  padding: 50px 0;
  color: var(--website-main-text-color);
}
.newsletter h2 {
  color: var(--website-main-text-color);
  font-size: 20px;
  font-weight: 700;
}
.newsletter .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newsletter input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  height: 30px;
}
.newsletter i {
  padding: 12px;
  background-color: var(--website-get-start-bg-color);
  color: var(--website-main-text-color);
}
.newsletter i:hover {
  cursor: pointer;
  background-color: var(--website-back-header-bg-color);
  color: var(--website-main-bg-color);
}
footer {
  background-color: var(--website-body-bg-color);
  padding: 2rem 0;
}
.footer-logo-part {
  display: flex;
  gap: 1rem;
  align-items: center;
}
footer .footer-logo {
  width: 100px;
  height: 100px;
}
footer .footer-logo img {
  width: 100%;
}
footer .logo span {
  color: var(--website-main-bg-color);
  font-size: 14px;
}
footer .logo p {
  color: var(--website-secondary-text-color);
  margin: 30px 0 15px 0;
  text-align: justify;
}
footer .logo .icon {
  background-color: var(--website-get-start-bg-color);
  color: var(--website-main-text-color);
  margin-right: 10px;
}
footer .logo .icon:hover {
  cursor: pointer;
  background-color: var(--website-main-bg-color);
  color: var(--website-menu-hover-text-color);
}
footer .footer-logo-part .footer-title h2 {
  font-size: 1.7em;
}
footer .box h3 {
  margin-bottom: 25px;
  font-size: 1em;
  font-weight: 500;
  padding-left: 5px;
  border-left: 4px solid var(--website-header-bg-color);
}
footer .items {
  padding: 10px 5px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}
footer .items .img {
  width: 90px;
  height: 70px;
}
footer .items img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
footer .text span,
footer .text i {
  margin-right: 5px;
}
footer h4 {
  font-weight: 400;
  margin-top: 5px;
}
footer .items .text p {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
footer .items .text p:hover {
  color: #00a0c6;
}
footer .last ul li {
  display: flex;
  margin-bottom: 50px;
}
footer .last i {
  font-size: 20px;
  color: #1eb2a6;
}
.legal {
  color: rgb(199, 199, 199);
  background-color: #2f2f2f;
}
.legal .subContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}
.box ul li {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .newsletter .left.row {
    padding-bottom: 10px;
  }
  .newsletter h2 {
    font-size: 16px;
  }
  .newsletter span {
    font-size: 14px;
  }
  footer .footer-logo-part .footer-title h2 {
    font-size: 1.3em;
  }
  .legal .subContainer {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    justify-content: center;
    text-align: center;
  }
}
.developer a {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.bottom-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.bottom-to-top-button:hover {
  opacity: 0.8;
}

.team {
  padding: 1rem 0;
}
.findTeamHeading {
  text-align: center;
  margin: 1rem;
}
.team-form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.insert-team-form {
  width: 100%;
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.insert-team-form .input-group {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 307px;
  flex-grow: 1;
}

.insert-team-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.insert-team-form .input-group input {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}

.team .items {
  transition: 0.5s;
}
.team .img {
  position: relative;
  height: 300px;
}
.team img {
  width: 100%;
  height: 100%;
}
.overlay {
  position: absolute;
  bottom: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: 0.5s;
  opacity: 0;
}
.overlay i {
  background-color: #000;
  color: #fff;
  margin: 5px;
}
.team .details {
  padding: 10px;
  text-align: left;
}
.team .details h2 {
  font-size: 18px;
  font-weight: 500;
  transition: 0.2s;
}
.team .details span {
  font-size: 12px;
}
.team .details p {
  font-size: 14px;
  color: gray;
  margin-top: 10px;
  transition: 0.5s;
  text-align: justify;
}
.team .details h3 {
  font-size: 20px;
  font-weight: 500;
  transition: 0.2s;
}

.team .items:hover {
  background-color: #00a0c6;
  color: #fff;
  cursor: pointer;
}
.team .items:hover p {
  color: #fff;
}
.team .items:hover .overlay {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .team .details h3 {
    font-size: 20px;
    font-weight: 500;
    transition: 0.2s;
  }
}

/* View Team Container */
.team.view {
  width: 50%;
  margin: 0 auto;
}
.team.view .container {
  padding: 1rem;
  border-radius: 10px;
}
.team.view .img {
  height: 400px;
}
.team.view img {
  width: 100%;
}

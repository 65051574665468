:root {
  --website-main-bg-color: #00a0c6;
  --website-main-text-color: #fff;
  --website-gray-color: gray;
}

.featureHome {
  height: auto;
  margin-top: 20px;
  background-color: var(--website-main-text-color);
}
.featureHome .container {
  padding: 20px 0;
}
.featureHome .featureContainer .items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.featureContainer .items .item {
  background-color: rgb(230, 229, 229);
  border-radius: 5px;
}

.featureHome .item {
  display: grid;
  grid-template-columns: 90px 1fr;
  background-color: var(--website-item-bg-color);
  padding: 20px;
  transition: 0.5s;
}
.featureHome .img {
  width: 90px;
}
.featureHome img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.featureHome .item .textFeature h2 {
  font-size: 16px;
}
.featureHome .item .textFeature p {
  font-size: 14px;
}
.featureHome .item:hover {
  background-color: var(--website-header-bg-color);
  color: var(--website-main-text-color);
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgba(0 0 0 /6%);
}

@media screen and (max-width: 768px) {
  .featureHome .container .items {
    grid-template-columns: 1fr;
  }
}

/* Insert Feature CSS */
.insert-form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.insert-feature-form {
  width: 100%;
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.insert-feature-form .insert-input-group {
  display: flex;
  gap: 10px;
  width: 100%;
  min-width: 240px;
  flex-grow: 1;
}

.insert-feature-form .input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 240px;
  flex-grow: 1;
}

.insert-feature-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.insert-feature-form .input-group input,
.insert-feature-form .input-group textarea {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}
.insert-feature-form .input-group p {
  font-size: 12px;
  line-height: 20px;
}

@media screen and (max-width: 768px) {
  .insert-feature-form .insert-input-group {
    flex-direction: column;
  }
}

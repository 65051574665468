:root {
  --website-main-text-color: #fff;
  --website-header-bg-color: #00a0c6;
  --website-other-text-color: #000;
  --website-secondary-text-color: gray;
  --website-course-btn-bg-color: rgb(249, 59, 59);
}
section.courseSection {
  padding: 2rem 0;
  background-color: #e1dede;
}
.coursesCard.view {
  width: 45%;
  margin: auto;
  padding: 2rem 0;
}
.coursesCard.view .content {
  padding: 0 1rem;
}
.coursesCard.view .rate {
  padding-top: 12px;
}
.coursesCard.view .courseDetails {
  margin-top: 2rem;
}

.allSpecificPart {
  margin: auto;
  border-top: 1px solid #00a0c6;
  border-bottom: 1px solid #00a0c6;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.view .allSpecificPart {
  border-top: none;
}
.coursesCard .coursePart {
  background-color: var(--website-main-text-color);
  border-radius: 8px;
  position: relative;
}

.coursesCard .coursePart:hover {
  box-shadow: 0px 0px 1px 2px var(--website-header-bg-color);
}
.coursesCard .courseTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: var(--website-header-bg-color);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.coursesCard .coursePart h2 {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: var(--website-main-text-color);
}
.coursesCard .items {
  padding: 0px 10px;
  text-align: center;
}
.coursesCard .text {
  text-align: left;
}
.coursesCard .rate {
  color: var(--website-header-bg-color);
  border-bottom: 1px solid var(--website-header-bg-color);
  padding-top: 4px;
}
.coursesCard .rate h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.coursesCard .details ul {
  margin-left: 16px;
  font-size: 12px;
  font-weight: 600;
}
.coursesCard .details ul li {
  list-style-type: circle;
}

.coursesCard .details .semester {
  background-color: rgb(58, 206, 214);
  color: var(--website-main-text-color);
  padding-left: 1rem;
}
.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: gray;
}
.coursesCard .details span {
  color: var(--website-header-bg-color);
  font-weight: 500;
  font-size: 14px;
}
.coursesCard spna.priceSymbol {
  font-size: 14px;
  font-weight: 800;
  padding-right: 3px;
}
.coursesCard .price {
  margin: 10px 0;
  background-color: var(--website-course-btn-bg-color);
  color: var(--website-main-text-color);
  padding: 5px;
  border-radius: 5px;
}
.coursesCard .price:hover {
  background-color: var(--website-header-bg-color);
  color: var(--website-main-text-color);
}
.coursesCard h3 {
  font-size: 13px;
  font-weight: 500;
  color: var(--website-header-bg-color);
}
.coursesCard .price h3 {
  color: var(--website-main-text-color);
  font-size: 16px;
}
.coursesCard .contact h4 {
  font-size: 12px;
  font-weight: 700;
  background-color: gold;
  border-radius: 5px;
  padding: 4px 0;
}
.coursesCard .contact:hover h4 {
  background-color: var(--website-header-bg-color);
  color: var(--website-main-text-color);
}
.coursePart .items {
  padding-bottom: 50px;
}
.coursesCard .more-details {
  padding: 5px;
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translate(-50%, -5px);
}
.coursesCard .more-details a {
  background-color: var(--website-header-bg-color);
  color: var(--website-main-text-color);
  padding: 0.3rem 1rem;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
}
.coursesCard .more-details a:hover {
  background-color: var(--website-get-start-bg-color);
  color: var(--website-main-text-color);
}
/* Youtube Section */
.youtube {
  text-align: center;
}
.youtubeContainer #heading {
  border-top: 1px solid #00a0c6;
  border-bottom: 1px solid #00a0c6;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.youtubeContainer .grid3 {
  grid-gap: 15px;
}
.youtubeContainer .box {
  box-shadow: 0 5px 25px -2px rgba(0 0 0 /6%);
  background-color: var(--website-main-text-color);
  transition: 0.5s;
  padding-bottom: 15px;
}
.youtubeContainer .img {
  width: 100%;
  height: 150px;
}
.youtubeContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.youtubeContainer a {
  color: var(--website-other-text-color);
}

.youtubeContainer a:hover {
  color: var(--website-main-text-color);
}

.youtubeContainer h2 {
  font-weight: 600;
  font-size: 14px;
  margin: 6px 0;
  line-height: 20px;
}
.youtubeContainer .course-number {
  background-color: var(--website-header-bg-color);
  padding: 2px 15px;
  width: fit-content;
  text-align: center;
  margin: auto;
  font-weight: 600;
  font-size: 14px;
  color: var(--website-main-text-color);
  border-radius: 5px;
}

.youtubeContainer a:hover p {
  background-color: var(--website-main-text-color);
  color: var(--website-header-bg-color);
}

.youtubeContainer .box:hover {
  background-color: var(--website-header-bg-color);
  border-radius: 5px;
  cursor: pointer;
}
.youtubeContainer .box:hover h1 {
  color: var(--website-main-text-color);
}
.youtubeContainer .noVideoFound p {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: red;
}

/* Inser Course details for admin */
.findCourseHeading {
  text-align: center;
  margin: 1rem;
}
.form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.course-form {
  width: 100%;
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.course-form .input-group {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 307px;
  flex-grow: 1;
}

.course-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.course-form .input-group input,
.course-form .input-group textarea,
.course-form .input-group select {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}

.course-form .input-group p {
  font-size: 12px;
  line-height: 16px;
}

.course-details {
  background-color: white;
  width: 80%;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 2rem;
}
.course-details .picture {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.course-details img {
  width: 125px;
  height: 150px;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.course-details .picture .institute {
  display: flex;
  gap: 10px;
}
.course-details .picture .institute img {
  width: 90px;
  height: 110px;
  border-radius: 0;
}
.course-details .picture h2 {
  font-size: 40px;
}
.course-details .picture p {
  margin-top: -14px;
  font-size: 14px;
}
.course-details .picture h3 {
  font-size: 22px;
}

.course-group {
  display: grid;
  grid-template-columns: 1fr 1rem 2fr;
  background: #eeeeee;
  padding: 10px;
  margin-bottom: 5px;
  align-items: center;
  font-style: italic;
}
.course-group h2 {
  font-size: 20px;
}
.course-group span {
  font-weight: 900;
  padding-right: 10px;
}
.userRegError {
  text-align: center;
  color: red;
  font-size: 22px;
  font-weight: 700;
}

/* Course Details CSS */
.courseContainer {
  background-color: gainsboro;
  margin: 1rem auto;
  padding: 1rem;
}
.addButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.priceSymbol {
  font-size: 14px;
  font-weight: 800;
  margin-right: 2px;
}

/* Update Part Style */
/* .ihCgAW {
  margin-top: -30px;
} */
.addButton a {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}
.addButton a:hover {
  background-color: #00a0c6;
  color: #fff;
}
.updateButton {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}
.updateButton a,
.updateButton button {
  flex: 1 1;
  background-color: none;
  padding: 0px;
  margin: 0px;
  border: 1px solid gray;
  width: 30px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #fff;
}
.updateButton a:hover,
.updateButton button:hover {
  background-color: #00a0c6;
}
.updateButton img {
  width: 20px;
}

.searchInput {
  padding: 10px 13px;
  outline: none;
  border-radius: 5px;
  border: 1px solid gray;
}

/* Course details show from Admin */
.courseMetaPart {
  background: #dddddd;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

/* Client Course Details Part */
.courseDetails {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.courseDetails .pricePart,
.courseDetails .applyPart {
  background-color: var(--website-course-btn-bg-color);
  color: var(--website-main-text-color);
  padding: 10px;
  border-radius: 5px;
}
.courseDetails .pricePart:hover,
.courseDetails .applyPart:hover {
  background-color: var(--website-header-bg-color);
  color: var(--website-main-text-color);
}

.courseDetails .pricePart h3,
.courseDetails .applyPart a {
  color: var(--website-main-text-color);
  font-size: 15px;
  font-weight: 600;
  /* padding: 0px 1rem; */
}
@media screen and (max-width: 768px) {
  .coursesCard.view {
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .course-form,
  .course-details {
    width: 100%;
  }
  .course-details .picture {
    display: block;
    text-align: center;
  }
  .course-details .picture h2 {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  .course-details .picture .institute img {
    width: 48px;
    height: 60px;
  }
  .course-group {
    grid-template-columns: 1fr 1rem;
  }
}

/* Admin Dashboard Style */
.loginBg {
  background-color: #e1dede;
  padding: 1rem 0;
}
.dashboard {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  margin: auto;
  margin: 1rem 0;
  padding: 1rem;
}
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #dfdede;
  margin-bottom: 1rem;
}
.dashboard-header a {
  background: white;
  padding: 10px 15px;
  border-radius: 10px;
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.dashboard-header h2 {
  font-size: 20px;
}
.dashboard-header a:hover {
  background-color: #00a0c6;
  color: #fff;
}

.dashboard-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 20px;
}
.card {
  background-color: #dfdede;
  border-radius: 10px;
}

.card-header {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1rem 0 1rem;
}
.card-header h3 {
  font-size: 17px;
  margin-bottom: 5px;
}
.card-header p {
  font-size: 30px;
  font-weight: 800;
}
.card-picture {
  width: 30%;
}
.card-picture img {
  width: 100%;
}
.card-link a {
  border-top: 1px solid #aba8a8;
  display: block;
  padding: 5px 10px;
}
.card-link a:hover {
  background-color: #00a0c6;
  color: #fff;
  font-weight: 600;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
hr.dashboard-hr {
  margin: 1rem 0;
}

.profilePicture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 4px;
}
.profilePicture img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

/* Login System */
.login-form-data {
  background-color: #fff;
  min-width: 250px;
  max-width: 500px;
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 10px;
}
h1.findAdminHeading {
  text-align: center;
  padding: 1rem;
}
.login-form-data .input-group {
  display: flex;
  flex-direction: column;
}

/* Forgot Password */
.forgot-password {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}
.forgot-password span a {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.forgot-password span a:hover {
  color: #00a0c6;
}

.login-form-data .forgot-btn {
  width: fit-content;
}

/* Admin Setting part */
.admin-setting {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.logo-favicon {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.logo-favicon .admin-form-data {
  flex-grow: 1;
}
.admin-insert-form {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
}
.admin-insert-form .input-group .login-form-data .input-group {
  display: flex;
  flex-direction: column;
}
.admin-form-data .input-group label,
.login-form-data .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}
.admin-form-data .input-group input,
.admin-form-data .input-group textarea,
.login-form-data .input-group input {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}
.admin-form-data .editProfilePicture {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
}

/* UserDashboard CSS */
.statusButton button {
  width: 5rem;
  cursor: pointer;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
}
.statusButton button.active {
  background-color: green;
}
.statusButton button.inactive {
  background-color: red;
}
.itemsTag {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}
.itemsTag .tag{
  background-color: #00a0c6;
  color: #fff;
  font-weight: 700;
  padding: 3px 8px;
  border-radius: 5px;
}
@media screen and (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    gap: 1rem;
  }
}

.blogBg {
  background-color: #e1dede;
}
.blogHeading {
  padding-top: 1rem;
}
.blog {
  padding: 1rem 0;
}
.blog .img {
  width: 100%;
  height: 160px;
  overflow: hidden;
}
.blog img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
}
.blog .items:hover img {
  transform: scale(1.05);
}
.blog .text {
  padding: 10px 20px;
}
.blog .text h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  transition: 0.5s;
  padding: 5px 0;
  text-align: justify;
}
.blog .text h2:hover {
  color: #1eb2a6;
  cursor: pointer;
}
.blog span {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  color: #1eb2a6;
}
.blog span label {
  margin-left: 10px;
  color: gray;
}
.blog span:nth-last-child(1) label {
  color: #1eb2a6;
}
.blog p {
  line-height: 20px;
  font-size: 14px;
  color: gray;
  text-align: justify;
  padding-bottom: 45px;
}
.blog .items {
  position: relative;
}
.blog .more-details {
  position: absolute;
  bottom: 22px;
  right: 20px;
}
.blog .more-details a {
  background-color: var(--website-header-bg-color);
  color: var(--website-main-text-color);
  padding: 0.3rem 1rem;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
}
.blog .more-details a:hover {
  background-color: var(--website-get-start-bg-color);
  color: var(--website-main-text-color);
}
.homeBlog {
  background-color: rgb(223 221 221);
  padding: 2rem 0;
}

/* Blogs Details CSS */
.blogDetail {
  display: grid;
  grid-template-columns: 1.5fr 4fr;
  gap: 10px;
  width: 100%;
  margin: 1rem 0;
}
.blogDetail .left-side,
.blogDetail .right-side {
  background-color: #fff;
  padding: 1rem;
  height: fit-content;
  min-width: 250px;
}
.blogDetail .left-side .recent-post-title {
  padding-bottom: 5px;
}
.blogDetail .left-side .recent-post-title h2 {
  font-size: 16px;
  font-weight: 500;
  border-left: 4px solid #00a0c6;
  padding-left: 5px;
  transition: all 0.5s ease;
}
.blogDetail .left-side .recent-post-title h2:hover {
  padding-left: 10px;
}
.blogDetail .left-side .recent-post-list {
  display: flex;
  flex-direction: column;
}
.blogDetail .left-side .recent-post-list a::before {
  content: ">";
}
.blogDetail .left-side .recent-post-list a {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  transition: all 0.5 ease;
  padding: 5px;
}
.blogDetail .left-side .recent-post-list a:hover {
  padding-left: 5px;
  background-color: #00a0c6;
  color: #fff;
}
.blogDetail .right-side .blog-feature-picture {
  width: 100%;
  height: 60vh;
}
.blogDetail .right-side .blog-feature-picture img {
  width: 100%;
  height: 100%;
}
.blog-title {
  padding: 10px 0;
}
.blog-description .blog-title h2 {
  font-size: 16px;
  text-align: justify;
}
.blog-description .blog-title .blog-other {
  margin-top: -4px;
}
.blog-description .blog-title .blog-other span {
  font-size: 12px;
}
.blog-description .blog-desc {
  padding: 10px 0;
  font-size: 14px;
}
.blog-description .blog-desc img {
  width: 100%;
}
.related-blog {
  background-color: rgb(223 221 221);
}

/* Insert blogs form */
.findBlogHeading {
  text-align: center;
  margin: 1rem;
}
.blog-form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.insert-blog-form {
  width: 100%;
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.insert-blog-form .input-group {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 307px;
  flex-grow: 1;
}
.insert-blog-form .input-group.blog-description {
  width: 100%;
}

.insert-blog-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.insert-blog-form .input-group input {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}

.insert-blog-form .input-group textarea {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}

/* Blog View Style */
.sun-editor .se-toolbar.se-toolbar-sticky {
  top: 4rem !important;
}
.blog-form-data .input-group p {
  font-size: 13px;
  line-height: 20px;
}
.blog-form-data .items {
  width: 80%;
}
.blog-form-data .items .img {
  height: 30rem;
}
.blog-form-data .items .img img {
  width: 100%;
  height: 100%;
}
.blog-form-data .items .text {
  padding: 2rem;
}
.blog-form-data .items .text img {
  width: 100%;
}
.blog-form-data .items .text p {
  padding: 5px 0px;
}
@media screen and (max-width: 768px) {
  .blogDetail {
    grid-template-columns: 1fr;
  }
  .blogDetail .left-side {
    order: 2;
  }
  .blogDetail .right-side .blog-feature-picture {
    height: 30vh;
  }
  .blog-description .blog-desc img {
    width: 100%;
  }
}

#react-select-5-listbox {
  z-index: 9999 !important;
}

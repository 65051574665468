/* Insert subject details for admin */
.findSubjectHeading {
  text-align: center;
  margin: 1rem;
}
.subject-form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.insert-subject-form {
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
}

.insert-subject-form .input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 240px;
  flex-grow: 1;
}

.insert-subject-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.insert-subject-form .input-group input,
.insert-subject-form .input-group textarea {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}
.insert-subject-form .input-group p {
  font-size: 12px;
  line-height: 20px;
}

.InsertApplyHeading,
.DownloadApplyHeading {
  text-align: center;
  padding: 2rem;
}
.DownloadApplyHeading h1 {
  color: green;
}
.DownloadApplyHeading h2 {
  color: red;
}
form.applyForm {
  padding-bottom: 2rem;
}
.form-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.apply-form {
  width: 100%;
  background-color: white;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.apply-form .input-group {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 260px;
  flex-grow: 1;
}

.apply-form .input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.apply-form .input-group input,
.apply-form .input-group textarea,
.apply-form .input-group select {
  margin-bottom: 1rem;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  background-color: #eeeeee;
}

.apply-details {
  background-color: white;
  width: 80%;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 2rem;
}
.apply-details p {
  font-size: 20px;
  line-height: 20px;
}
.apply-details .picture {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.apply-details img {
  width: 125px;
  height: 150px;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.apply-details .picture .institute {
  display: flex;
  gap: 10px;
}
.apply-details .picture .institute img {
  width: 90px;
  height: 110px;
  border-radius: 0;
}
.apply-details .picture h2 {
  font-size: 30px;
}
.apply-details .picture p {
  margin-top: -7px;
  font-size: 18px;
}
.apply-details .picture .applied-course {
  margin-top: 10px;
  font-size: 22px;
}
.apply-details .picture h3 {
  font-size: 22px;
}

.apply-group {
  display: grid;
  grid-template-columns: 1fr 1rem 2fr;
  background: #eeeeee;
  padding: 10px;
  margin-bottom: 5px;
  align-items: center;
  font-style: italic;
}
.apply-group h2 {
  font-size: 18px;
}
.apply-group span {
  font-weight: 900;
  padding-right: 10px;
}

.printBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.printBtn button {
  background-color: #00a0c6;
  color: white;
  border-radius: 10px;
}
.printBtn button:hover {
  background-color: rgb(60, 124, 241);
}

@media screen and (max-width: 600px) {
  .apply-form,
  .apply-details {
    width: 100%;
  }
  .apply-details .picture {
    display: block;
    text-align: center;
  }
  .apply-details .picture h2 {
    font-size: 20px;
    margin-bottom: 1rem;
  }
  .apply-details .picture .institute img {
    width: 48px;
    height: 60px;
  }
  .apply-group {
    grid-template-columns: 1fr 1rem;
  }
}
